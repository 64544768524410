/* line 21, /Users/dvcln/Sites/mplou0001.local/www/sites/default/themes/mplou_theme/assets/sass/print.sass */
a {
  color: #000000 !important;
  text-decoration: none;
}

/* line 25, /Users/dvcln/Sites/mplou0001.local/www/sites/default/themes/mplou_theme/assets/sass/print.sass */
img {
  display: block;
  margin: 10pt 0;
}

/* line 29, /Users/dvcln/Sites/mplou0001.local/www/sites/default/themes/mplou_theme/assets/sass/print.sass */
iframe,
form,
.actions-links,
.block-more-link,
.breadcrumb,
.btn,
.event-link,
.footer,
.footer-bottom,
.footer-top,
.header,
.links,
.tabs,
.messages,
.news-link,
.panels,
.section-link,
.slideshow-btn,
.slideshow-link,
.views-field-view-node {
  display: none;
}
